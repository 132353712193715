<template>
<div class="home">
  <Navigation />
  <div class="container">
    <div class="row row-split-content">
      <div class="content-width-medium">
        <h1>{{ $auth.user.name }}</h1>
        <div class="form-wrapper margin-top w-form">
          <div class="form-group">
            <div class="form-grid-halves">
              <input
                type="text"
                class="form-input w-input"
                maxlength="256"
                name="first-name"
                data-name="First Name"
                placeholder="First Name"
                id="first-name"
                required
                v-model="first"
              />
              <input
                type="text"
                class="form-input w-input"
                maxlength="256"
                name="Last-Name"
                placeholder="Last Name"
                id="Last-Name-5"
                ms-field="last-name"
                v-model="last"
              />
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-input w-input"
              maxlength="256"
              name="company-name"
              placeholder="Brokerage"
              v-model="brokerage"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-input w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              :placeholder="$auth.user.email"
              readonly
            />
          </div>

<div class="button-row">
          <button class="button w-button" v-on:click="updateUserData">Save</button>
          <button class="button logout" v-on:click="logout">Log Out</button>    
          <a class="typeform-share button cancel" v-bind:click="track" v-bind:href="typeformUrl" data-mode="popup" data-submit-close-delay="30" target="_blank">Cancel Subscription</a>
          </div>
        </div>
      </div>
      <div class="content-width-medium" v-if="!paid">
        <h1>Enter your payment info to get access</h1>
        <stripe-checkout
          ref="checkoutRef"
          :pk="publishableKey"
          :items="items"
          :successUrl="successUrl"
          :cancelUrl="cancelUrl"
          :customerEmail="email"
        >
          <template slot="checkout-button">
            <button @click="checkout">Pay Now</button>
          </template>
        </stripe-checkout>
      </div>
    </div>
  </div>
  </div>
</template>
 
<script>
import { StripeCheckout } from "vue-stripe-checkout";
import ProfileService from "@/services/profileService.js";
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    StripeCheckout,
    Navigation
  },
  data: () => ({
    first: null,
    last: null,
    brokerage: null,
    loading: false,
    paid: false,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    items: [
      {
        plan: process.env.VUE_APP_STRIPE_PLAN_MONTHLY,
        quantity: 1
      }
    ],
    successUrl: process.env.VUE_APP_URL + "/membership/welcome",
    cancelUrl: process.env.VUE_APP_URL + "/profile",
    email: null,
    typeformUrl: null
  }),
  mounted()
  {
    (function() { var js,q,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()
  },
  created() {

    var userId = this.$auth.user.sub;
    this.email = this.$auth.user.email;
    this.typeformUrl = "https://thematchartist.typeform.com/to/eZoIu3?email="+this.email;
    var vm = this;
    this.$auth.getTokenSilently().then(function(result) {
      vm.bearer = result;
      console.log(result);
      ProfileService.getUserInfo(userId, result)
        .then(response => {
          
          var meta = response.data.user_metadata;
          vm.first = meta.first_name;
          vm.last = meta.last_name;
          vm.brokerage = meta.brokerage;
          vm.paid = response.data.app_metadata.status;
          if (vm.paid == null || vm.paid == "") {
            vm.paid = false;
          }
        })
        .catch(error => {
          console.log("there was an error getting the user metadata", error);
        });
    });
  },
  methods: {
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
    updateUserData() {
      var userId = this.$auth.user.sub;
      ProfileService.setUserMetaData(
        userId,
        this.bearer,
        this.first,
        this.last,
        this.brokerage
      )
        .then(response => {
          console.log(response);
           this.$swal(
        "Your user data has been updated!"
            );
        })
        .catch(error => {
          console.log("there was an error setting the user metadata", error);
        });

      return true;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    trackCancel() {
      // eslint-disable-next-line no-undef
      mixpanel.track("Cancelled Account", {
        Intent: "This user opened up the cancel type form(they may have not actually cancelled",
      });
    },
    cancel() {
      this.$swal(
        "We are sorry to see you go. But we will get this canceled for you."
      );
      var currentUser = this.$auth.user.email;
      var userObj = {
        user: currentUser
      };
      var xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://hooks.zapier.com/hooks/catch/1261564/ohepo8y/",
        true
      );
      xhr.send(
        JSON.stringify({
          body: userObj
        })
      );
    }
  }
};
</script> 
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row
{
  margin-bottom: 25px;
}
</style>