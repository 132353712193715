import axios from "axios";

//const clientId = "8sU0u7eemnC4nebOVSj1Xb8E9d4ryvrx";
export default {
    /**
   * @deprecated Use {userService.getById} instead
   */
  getUserInfo(userId, token) {
    //To Do figure out how to declare this above and just update the auth on every call
    const apiClient = axios.create({
      baseURL: "https://agentnest.auth0.com/api/v2/",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    });
    return apiClient.get("users/" + userId);
  },

  /**
   * @deprecated Use {userService.patch} instead
   */
  setUserMetaData(userId, token, first, last, brokerage) {
    //To Do figure out how to declare this above and just update the auth on every call
    const apiClient = axios.create({
      baseURL: "https://agentnest.auth0.com/api/v2/",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    });
    var meta = {
      "user_metadata":{
        first_name: first,
        last_name: last,
        brokerage: brokerage
      }
    };
    return apiClient.patch("users/" + userId,meta);
  },
};
